/* eslint-disable import/prefer-default-export */
import { ref, computed } from '@vue/composition-api';

export function useInputs({ emit, message, inputRef }) {
  const focused = ref(false);
  const dirty = ref(false);
  const valid = ref(true);

  function emitUpdate(event) {
    emit('input', event?.target?.value);
  }

  function onFocus() {
    focused.value = true;
    dirty.value = true;
    valid.value = true;
  }

  function onBlur(event) {
    focused.value = false;
    dirty.value = event?.target?.value !== '';
    valid.value = event?.target?.checkValidity();
  }

  function focusInputRef() {
    inputRef.value.focus();
  }

  const validationMessage = computed(() => {
    if (message) {
      return message;
    }
    const required = inputRef?.value?.required;
    const type = inputRef?.value?.type;
    if (!type) {
      return 'Input is invalid';
    }
    if (required) {
      return `${type} is required`;
    }
    return `${type} is invalid`;
  });

  return {
    emitUpdate,
    validationMessage,
    onFocus,
    onBlur,
    focusInputRef,
    dirty,
    focused,
    valid,
  };
}
