<script>
import { computed, defineComponent, reactive, ref } from '@vue/composition-api';
import BaseInput from '@/components/base/base-input.vue';
import BaseButton from '@/components/base/base-button.vue';
import Logo from '@/components/logo.vue';
import ErrorNotification from '@/components/error-notification.vue';
import PaymentArtifactHeader from '@/components/payment-artifact-header.vue';
import BaseIcon from '@/components/base/base-icon.vue';
import { ROUTE_REGISTER, ROUTE_FORGOT_PASSWORD, ROUTE_CUSTOMER_DASHBOARD, ROUTE_PAY } from '@/lib/router';

import authApi from '@/api/auth';
import { useRouter } from '@/use/router';
import { renderErrorMessage } from '@/lib/login';
import { useBrand } from '@/use/brand';
import { useStore } from '@/use/store';
import { useApiErrors } from '@/use/errors';
import { USER_GET_PROFILE } from '@/lib/store';

export default defineComponent({
  name: 'Login',
  components: {
    BaseInput,
    BaseButton,
    Logo,
    ErrorNotification,
    PaymentArtifactHeader,
    BaseIcon,
  },
  props: {
    artifactDetails: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    const brand = useBrand();
    const router = useRouter();
    const store = useStore();
    const form = reactive({ email: '', password: '' });
    const { apiError, isError, clearError, setError } = useApiErrors(renderErrorMessage);

    const isLoading = ref(false);
    const isButtonDisabled = computed(() => !form.password || !form.email);
    function redirectBack() {
      router.push({ name: ROUTE_PAY, params: { artifactId: props.artifactDetails.artifactId } });
    }
    function routeNextPage() {
      if (props.artifactDetails) {
        redirectBack();
        return;
      }
      router.push({ name: ROUTE_CUSTOMER_DASHBOARD });
    }
    async function submitLogin() {
      try {
        clearError();
        isLoading.value = true;
        await authApi.postLogin(form);
        await store.dispatch(USER_GET_PROFILE);
        routeNextPage();
      } catch (error) {
        setError(error);
      } finally {
        isLoading.value = false;
      }
    }

    return {
      form,
      apiError,
      isLoading,
      isError,
      isButtonDisabled,
      submitLogin,
      brand,
      ROUTE_REGISTER,
      ROUTE_FORGOT_PASSWORD,
      ROUTE_CUSTOMER_DASHBOARD,
      redirectBack,
    };
  },
});
</script>

<template>
  <div class="w-full">
    <div v-if="artifactDetails" class="flex flex-col-reverse md:flex-row justify-between items-center my-4">
      <span @click="redirectBack" data-testid="directBack" class="self-start md:self-auto cursor-pointer">
        <BaseIcon size="fa-md" class="back-icon" icon="fa-arrow-left"
      /></span>
      <PaymentArtifactHeader :artifactDetails="artifactDetails" class="w-11/12 justify-end" />
    </div>
    <Logo v-else class="my-10" size="small" />
    <h1 class="text-2xl">Login to {{ brand.name }}</h1>
    <p class="text-shades-dark text-sm">Welcome back</p>
    <form class="flex flex-col my-6" @submit.prevent="submitLogin">
      <BaseInput
        id="emailInput"
        data-testid="emailInput"
        required
        type="email"
        name="email"
        v-model="form.email"
        :value="form.email"
        label="Email address"
        title="Please enter your email address"
        class="mb-2"
      />
      <BaseInput
        id="passwordInput"
        data-testid="passwordInput"
        required
        type="password"
        name="password"
        v-model="form.password"
        label="Password"
        title="Please enter your password"
        class="mb-1"
      />
      <router-link class="text-link text-sm" :to="{ name: ROUTE_FORGOT_PASSWORD }"> Forgot your password? </router-link>
      <ErrorNotification class="my-3" v-show="isError" type="is-warning" :errorMessages="apiError" />
      <BaseButton class="min-w-full my-3" type="submit" :loading="isLoading" :disabled="isButtonDisabled">
        Login to {{ brand.name }}
      </BaseButton>
      <div>
        <span class="text-sm">Don't have an account?</span>&nbsp;
        <router-link class="text-link" :to="{ name: ROUTE_REGISTER }">Sign up</router-link>
      </div>
    </form>
  </div>
</template>

<style scoped >
</style>
