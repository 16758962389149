var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inline-flex flex-col h-16"},[_c('div',{class:[
      'base-input-container',
      "relative\n      inline-flex\n      flex-col\n      p-1\n      h-12\n      border\n      rounded\n      border-shades\n      outline-none\n      hover:outline-none hover:ring-1 hover:ring-primary hover:border-transparent\n      ",
      { active: _vm.focused, readonly: _vm.readonly } ],on:{"click":_vm.focusInputRef}},[_c('input',_vm._g(_vm._b({directives:[{name:"cleave",rawName:"v-cleave",value:(_vm.formattingOptions),expression:"formattingOptions"}],ref:"baseInput",staticClass:"\n        mt-4\n        mx-1\n        outline-none\n        text-sm\n        placeholder-transparent\n        focus:placeholder-shades\n        read-only:bg-shades-lighter\n      ",attrs:{"id":_vm.id,"type":_vm.inputType,"placeholder":_vm.placeholder,"readonly":_vm.readonly},domProps:{"value":_vm.value},on:{"focus":_vm.onFocus,"blur":_vm.onBlur}},'input',_vm.$attrs,false),Object.assign({}, _vm.$listeners,
        {input: _vm.emitUpdate}))),_c('label',{directives:[{name:"show",rawName:"v-show",value:(_vm.label),expression:"label"}],class:[
        'base-input-label',
        "\n        transition\n        ease-out\n        duration-300\n        top-1/4\n        mx-1\n        absolute\n        text-shades\n        transform\n        ",
        { active: _vm.dirty } ],attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.formattedLabel)+" ")]),(_vm.isPassword && !_vm.revealPassword)?_c('BaseIcon',{staticClass:"base-input-icon cursor-pointer",attrs:{"icon":"fa-eye","size":"fa-lg"},nativeOn:{"click":function($event){return _vm.togglePasswordReveal.apply(null, arguments)}}}):_vm._e(),(_vm.isPassword && _vm.revealPassword)?_c('BaseIcon',{staticClass:"base-input-icon cursor-pointer",attrs:{"icon":"fa-eye-slash","size":"fa-lg"},nativeOn:{"click":function($event){return _vm.togglePasswordReveal.apply(null, arguments)}}}):_vm._e(),(_vm.icon && !_vm.isPassword)?_c('BaseIcon',{staticClass:"base-input-icon",attrs:{"icon":_vm.icon,"size":"fa-lg"}}):_vm._e()],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.valid),expression:"!valid"}],staticClass:"text-xs text-danger"},[_vm._v(" "+_vm._s(_vm.validationMessage)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }