<script>
import { computed, defineComponent, ref } from '@vue/composition-api';
import { useInputs } from '@/use/inputs';
import BaseIcon from './base-icon.vue';

export default defineComponent({
  components: { BaseIcon },
  name: 'BaseInput',
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    label: {
      type: String,
      required: false,
    },
    value: {
      type: [String, Number, Date],
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    icon: {
      type: String,
      required: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * FormattingOptions prop allows a variety of cleave.js options for formatting inputs
     * https://nosir.github.io/cleave.js/
     * https://github.com/nosir/cleave.js/blob/master/doc/options.md#date
     * example below:
     * :formattingOptions="{
     * date: true,
     * datePattern: ['d','m','Y']
     * }"
     * this example sets a date format and provides the equivalent input format
     * see the documentation link above for examples of phone and credit card formatting
     */
    formattingOptions: {
      type: Object,
      required: false,
    },
  },
  setup(props, { emit }) {
    const baseInput = ref(null);
    const inputType = ref(props.type);
    const revealPassword = ref(false);
    const { emitUpdate, validationMessage, onFocus, onBlur, focusInputRef, dirty, valid, focused } = useInputs({
      emit,
      message: props.title,
      inputRef: baseInput,
    });
    dirty.value = props.value !== '';

    const formattedLabel = computed(() => {
      if (dirty.value) {
        return props.label;
      }
      let formattedPlaceholder = '';
      if (props.placeholder) {
        formattedPlaceholder = `(${props.placeholder})`;
      }
      return `${props.label} ${formattedPlaceholder}`.trim();
    });

    const isPassword = computed(() => props.type === 'password');

    function togglePasswordReveal() {
      if (inputType.value === 'password') {
        inputType.value = 'text';
        revealPassword.value = true;
        return;
      }
      inputType.value = 'password';
      revealPassword.value = false;
    }

    return {
      emitUpdate,
      validationMessage,
      onFocus,
      onBlur,
      focused,
      dirty,
      valid,
      baseInput,
      focusInputRef,
      formattedLabel,
      inputType,
      revealPassword,
      togglePasswordReveal,
      isPassword,
    };
  },
});
</script>

<template>
  <div class="inline-flex flex-col h-16">
    <div
      @click="focusInputRef"
      :class="[
        'base-input-container',
        `relative
        inline-flex
        flex-col
        p-1
        h-12
        border
        rounded
        border-shades
        outline-none
        hover:outline-none hover:ring-1 hover:ring-primary hover:border-transparent
        `,
        { active: focused, readonly },
      ]"
    >
      <input
        :id="id"
        v-cleave="formattingOptions"
        ref="baseInput"
        @focus="onFocus"
        @blur="onBlur"
        class="
          mt-4
          mx-1
          outline-none
          text-sm
          placeholder-transparent
          focus:placeholder-shades
          read-only:bg-shades-lighter
        "
        v-bind="$attrs"
        :type="inputType"
        :value="value"
        v-on="{
          ...$listeners,
          input: emitUpdate,
        }"
        :placeholder="placeholder"
        :readonly="readonly"
      />
      <label
        :for="id"
        v-show="label"
        :class="[
          'base-input-label',
          `
          transition
          ease-out
          duration-300
          top-1/4
          mx-1
          absolute
          text-shades
          transform
          `,
          { active: dirty },
        ]"
      >
        {{ formattedLabel }}
      </label>
      <BaseIcon
        class="base-input-icon cursor-pointer"
        @click.native="togglePasswordReveal"
        v-if="isPassword && !revealPassword"
        icon="fa-eye"
        size="fa-lg"
      />
      <BaseIcon
        class="base-input-icon cursor-pointer"
        v-if="isPassword && revealPassword"
        @click.native="togglePasswordReveal"
        icon="fa-eye-slash"
        size="fa-lg"
      />
      <BaseIcon class="base-input-icon" v-if="icon && !isPassword" :icon="icon" size="fa-lg" />
    </div>
    <span v-show="!valid" class="text-xs text-danger"> {{ validationMessage }} </span>
  </div>
</template>

<style scoped>
input:read-only {
  @apply bg-shades-lighter outline-none ring-0 border-none;
}
.base-input-container.readonly {
  @apply bg-shades-lighter outline-none ring-0 border-none;
}
.base-input-container.active {
  @apply outline-none ring-2 ring-primary border-transparent;
}
.base-input-label.active {
  @apply -translate-y-2 text-xs;
}
.base-input-icon {
  @apply absolute top-1/3 right-2;
}
</style>
