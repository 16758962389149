<template>
  <div class="error-notification break-words p-4 rounded bg-danger-lighter text-danger-darker">
    <p class="text-sm font-bold">Please fix the following errors:</p>
    <p class="text-sm" v-if="errorMessages.detail" v-html="errorMessages.detail" />
    <ul v-if="errorMessages.errors">
      <li
        class="
          before:content-['x']
          before:font-bold
          before:text-sm
          before:text-white
          before:inline-block
          before:w4
          before:ml-0.5
        "
        v-for="item in errorMessages.errors"
        v-bind:key="item"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ErrorNotification',
  props: {
    errorMessages: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
</style>
