<script>
import { defineComponent } from '@vue/composition-api';
import { PRIMARY, variants } from '@/lib/variants';
import BaseIcon from '@/components/base/base-icon.vue';

export default defineComponent({
  components: { BaseIcon },
  name: 'BaseButton',
  props: {
    variant: {
      type: String,
      required: false,
      default: PRIMARY,
      validator: (value) => Object.values(variants()).includes(value),
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    rounded: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
</script>

<template>
  <button
    v-bind="$attrs"
    v-on="$listeners"
    :class="[
      'base-button',
      'font-extrabold py-3 px-4 transition duration-300 ease-in-out',
      variant,
      { outlined, rounded },
    ]"
    :disabled="disabled"
  >
    <base-icon v-if="loading" icon="fa-circle-notch fa-spin" size="fa-lg" />
    <slot v-else />
  </button>
</template>

<style scoped>
.base-button {
  min-width: 144px;
  border-radius: 4px;
}
.base-button:disabled {
  @apply bg-shades hover:bg-shades cursor-not-allowed;
}
.base-button.rounded {
  @apply rounded-xl;
}
.primary {
  @apply bg-primary text-white hover:bg-primary-dark focus:bg-primary;
}
.primary.outlined:not(:disabled) {
  @apply bg-transparent text-primary ring-1 ring-primary hover:text-white hover:bg-primary;
}
.accent {
  @apply bg-accent text-white hover:bg-accent-dark focus:bg-accent;
}
.accent.outlined:not(:disabled) {
  @apply bg-transparent text-accent ring-1 ring-accent hover:text-white hover:bg-accent;
}
.shades {
  @apply bg-shades text-white hover:bg-shades-dark focus:bg-shades;
}
.shades.outlined:not(:disabled) {
  @apply bg-transparent text-shades ring-1 ring-shades hover:text-white hover:bg-shades;
}
.success {
  @apply bg-success text-white hover:bg-success-dark focus:bg-success;
}
.success.outlined:not(:disabled) {
  @apply bg-transparent text-success ring-1 ring-success hover:text-white hover:bg-success;
}
.warning {
  @apply bg-warning text-dark hover:bg-warning-dark focus:bg-warning;
}
.warning.outlined:not(:disabled) {
  @apply bg-transparent text-warning-darker ring-1 ring-warning-darker hover:text-dark hover:bg-warning-darker;
}
.danger {
  @apply bg-danger text-white hover:bg-danger-dark focus:bg-danger;
}
.danger.outlined:not(:disabled) {
  @apply bg-transparent text-danger ring-1 ring-danger hover:text-white hover:bg-danger;
}
.dark {
  @apply bg-dark text-white hover:bg-dark-light focus:bg-dark;
}
.dark.outlined:not(:disabled) {
  @apply bg-transparent text-dark ring-1 ring-dark hover:text-white hover:bg-dark;
}
</style>
