/* eslint-disable import/prefer-default-export */
import { computed, ref } from '@vue/composition-api';

/**
 * Function that returns a template to provide detail to the error.
 * The template will override the api response detail property.
 * @callback ErrorTemplateFunc
 * @param {Error} err - error object eg Axios error object
 * @return {string} return
 */

/**
 * Utility function to help with api errors.
 * If a template function is provided, the output will override the 'detail' property of api error.
 * If the error is not an api response, use a generic template.
 * @param {ErrorTemplateFunc | undefined } mapperFunc template mapper func
 * @param {string} genericError error message to display
 * @returns
 */
export function useApiErrors(mapperFunc = undefined, genericError = 'Something went wrong') {
  const apiError = ref({
    title: '',
    detail: '',
    type: '',
  });

  const isError = computed(() => apiError.value.title !== '' || apiError.value.detail !== '');

  function clearError() {
    apiError.value = {
      title: '',
      detail: '',
      type: '',
    };
  }

  function setError(err) {
    const resp = err?.response?.data;

    if (!resp) {
      apiError.value = {
        title: '',
        detail: genericError,
      };
      return;
    }

    if (!mapperFunc) {
      apiError.value = resp;
      return;
    }

    let detail = mapperFunc(err);
    if (!detail) {
      detail = genericError;
    }
    apiError.value = {
      ...resp,
      detail,
    };
  }

  return {
    apiError,
    isError,
    clearError,
    setError,
  };
}
