import { STATUS_FORBIDDEN, STATUS_CONFLICT, STATUS_BAD_REQUEST } from '@/lib/api';

export const TEMPLATE_BAD_CREDS = `Incorrect username or password. You can try to reset your
    password below or contact <a href="mailto:${process.env.VUE_APP_BRAND_CONTACT_EMAIL}?subject='Password Issue'" style="font-size: 1rem;">${process.env.VUE_APP_BRAND_CONTACT_EMAIL}</a>
    and we'll help you.`;

export const TEMPLATE_SUSPENDED = `It looks like your account is suspended, please contact
    <a href="mailto:${process.env.VUE_APP_BRAND_CONTACT_EMAIL}?subject='Account Suspended'" style="font-size: 1rem;">${process.env.VUE_APP_BRAND_CONTACT_EMAIL}</a>
    to resolve this issue.`;

export const TEMPLATE_NOT_VERIFIED = `We've resent  a verification link to your email - If you do not receive it within 10 minutes, please check your Junk Email folder or contact
    <a href="mailto:${process.env.VUE_APP_BRAND_CONTACT_EMAIL}?subject='Not Verified Email'" style="font-size: 1rem;">${process.env.VUE_APP_BRAND_CONTACT_EMAIL}</a> for help`;

export const TEMPLATE_GENERIC = `Sorry! Something went wrong, please contact us <a href="mailto:${process.env.VUE_APP_BRAND_CONTACT_EMAIL}?subject='Password Issue'" style="font-size: 1rem;">${process.env.VUE_APP_BRAND_CONTACT_EMAIL}</a>
    and we'll help you.`;

/**
 * returns the error message template based on error type
 * @param {import("axios").AxiosError} err error type from message payload
 * @returns {String}
 */
export function renderErrorMessage(err) {
  const { status } = err?.response;
  const repo = {
    [STATUS_BAD_REQUEST]: TEMPLATE_BAD_CREDS,
    [STATUS_FORBIDDEN]: TEMPLATE_SUSPENDED,
    [STATUS_CONFLICT]: TEMPLATE_NOT_VERIFIED,
  };

  if (repo[status] === undefined) {
    return TEMPLATE_GENERIC;
  }
  return repo[status];
}
