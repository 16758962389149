<script>
import { computed, defineComponent, reactive, ref } from '@vue/composition-api';
import authApi from '@/api/auth';
import { ROUTE_LOGIN } from '@/lib/router';
import BaseInput from '@/components/base/base-input.vue';
import BaseButton from '@/components/base/base-button.vue';
import ErrorNotification from '@/components/error-notification.vue';
import { useBrand } from '@/use/brand';
import { useRouter } from '@/use/router';
import { useApiErrors } from '@/use/errors';

export default defineComponent({
  name: 'ResetPassword',
  props: {
    jwt: {
      required: true,
      type: String,
    },
  },
  components: {
    BaseInput,
    BaseButton,
    ErrorNotification,
  },
  setup(props) {
    const brand = useBrand();
    const router = useRouter();
    const isLoading = ref(false);

    const form = reactive({
      password: '',
      passwordConfirm: '',
      token: props.jwt,
    });

    const { apiError, isError, clearError, setError } = useApiErrors();

    const passwordsFilled = computed(() => form.password !== '' && form.passwordConfirm !== '');
    const notSamePasswords = computed(() => {
      if (passwordsFilled.value) {
        return form.password !== form.passwordConfirm;
      }
      return false;
    });
    const buttonDisabled = computed(() => !form.password || !form.passwordConfirm || notSamePasswords.value);

    async function handleSubmit() {
      try {
        clearError();
        isLoading.value = true;
        await authApi.resetPassword(form);
        router.push({ name: ROUTE_LOGIN });
      } catch (error) {
        setError(error);
      } finally {
        isLoading.value = false;
      }
    }

    return {
      brand,
      isLoading,
      isError,
      apiError,
      form,
      passwordsFilled,
      buttonDisabled,
      notSamePasswords,
      handleSubmit,
    };
  },
});
</script>

<template>
  <div>
    <h1 class="text-2xl">Reset your password</h1>
    <p class="text-shades-darker my-4">You request was successful, you can now reset your password</p>
    <p class="text-shades-darker my-4">
      Passwords need to be longer than 8 characters and can contain numbers and special characters
    </p>
    <p class="text-shades-darker my-6">
      If you need any additional support - drop us a mail on
      <a class="text-link" :href="`mailto:${brand.contactEmail}`">{{ brand.contactEmail }}</a>
    </p>
    <form class="flex flex-col" @submit.prevent="handleSubmit">
      <BaseInput
        id="password1Input"
        required
        minlength="8"
        type="password"
        name="password"
        v-model="form.password"
        label="New Password"
        title="Password must be 8 characters or more"
      />
      <BaseInput
        class="my-2"
        id="password2Input"
        required
        type="password"
        name="confirmpassword"
        v-model.lazy="form.passwordConfirm"
        label="Confirm New Password"
        title="Password is required"
      />
      <p class="text-xs -mt-2 text-danger" v-show="notSamePasswords">Passwords don't match.</p>
      <p class="text-xs text-success-dark -mt-2" v-show="!notSamePasswords && passwordsFilled">Passwords match.</p>
      <ErrorNotification v-show="isError" class="errors" :errorMessages="apiError" />
      <BaseButton
        class="w-full mx-auto mt-3 mb-4"
        primary
        type="submit"
        :loading="isLoading"
        :disabled="buttonDisabled"
      >
        Update Password
      </BaseButton>
    </form>
  </div>
</template>
