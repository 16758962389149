<script>
import { ref, defineComponent } from '@vue/composition-api';
import BaseInput from '@/components/base/base-input.vue';
import BaseButton from '@/components/base/base-button.vue';
import ErrorNotification from '@/components/error-notification.vue';
import { ROUTE_LOGIN } from '@/lib/router';

import authApi from '@/api/auth';
import { useBrand } from '@/use/brand';
import { useApiErrors } from '@/use/errors';

export default defineComponent({
  components: {
    BaseInput,
    BaseButton,
    ErrorNotification,
  },
  name: 'ForgotPassword',
  setup() {
    const brand = useBrand();
    const isLoading = ref(false);

    const emailForm = ref('');
    const emailSent = ref(false);

    const { apiError, isError, clearError, setError } = useApiErrors();

    async function emailPasswordReset() {
      try {
        clearError();
        isLoading.value = true;
        await authApi.postPasswordReset(emailForm.value);
        emailSent.value = true;
      } catch (error) {
        setError(error);
        isError.value = true;
      } finally {
        isLoading.value = false;
      }
    }

    return {
      brand,
      isLoading,
      isError,
      apiError,
      emailForm,
      emailSent,
      emailPasswordReset,
      clearError,
      ROUTE_LOGIN,
    };
  },
});
</script>

<template>
  <div>
    <h1 class="text-2xl">Password Reset</h1>
    <transition name="fade" mode="out-in">
      <form v-if="!emailSent" @submit.prevent="emailPasswordReset">
        <p class="text-sm my-6 text-shades-darker">
          Enter your email below and we'll send you an email with guidance on how to reset your password. If you need
          any additional support - drop us an email on
          <a class="text-link" :href="`mailto:${brand.contactEmail}`">{{ brand.contactEmail }}</a>
        </p>
        <BaseInput
          id="passwordResetInput"
          class="w-full"
          @focus="clearError"
          type="email"
          required
          v-model="emailForm"
          label="Email"
          placeholder="you@mail.com"
          title="Email is required"
        />
        <ErrorNotification class="my-1" v-show="isError" :errorMessages="apiError" />
        <div class="my-8 flex flex-col">
          <BaseButton class="w-full mx-auto" :loading="isLoading" type="submit">Reset Password</BaseButton>
          <router-link class="text-link mt-6" :to="{ name: ROUTE_LOGIN }">Back to Login</router-link>
        </div>
      </form>
      <div class="text-shades-darker mt-10" v-else>
        <figure class="my-10 flex justify-center sm:justify-start">
          <img src="@/assets/confirm_email.svg" alt="email sent" />
        </figure>
        <p>
          Your request has been received. You should receive instructions via email shortly if you entered a valid
          address.
        </p>
        <p class="my-6">You can now close this tab.</p>
      </div>
    </transition>
  </div>
</template>

<style  scoped>
</style>
